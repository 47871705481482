import { useBreakpoints } from '@vueuse/core'
import scssBreakpoints from '@/assets/styles/breakpoints.module.scss'

const { max, medium, pc, min, sp } = scssBreakpoints

export const breakpoints = useBreakpoints({
  max,
  medium,
  pc,
  min,
  sp,
})
